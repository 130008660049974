/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// const cypressRemoteUrl = (process.env.VUE_APP_STAGING_URL && process.env.VUE_APP_STAGING_URL !== 'none') ? process.env.VUE_APP_STAGING_URL : 'none'

window.XPEDEO_SETTINGS = {
  content: {
    remoteUrl: '',
    useStaticPageFiles: false
  },
  theme: 't-clear',
  showLocationSettingsWarning: true
}
