export default {
  content: {
    startPageId: null,
    remoteUrl: '',
    useStaticPageFiles: false,
    welcomeScreenId: null,
    nfcPageId: null,
    langCode: 'de_DE'
  },
  radio: {
    wifi: {
      APs: null, /* Example:            [
              {
                SSID: 'xpedeo',
                authType: 'WPA',
                password: 'abcDEF123',
                hidden: false
              }
            ]
          */
      alwaysOn: false
    },
    nfcActive: false
  },
  screen: {
    brightness: 200,
    standbySeconds: 120,
    halfDimMilliseconds: 120 * 1000,
    fullDimMilliseconds: 180 * 1000,
    halfDimValue: 100,
    fullDimValue: 0
  },
  battery: {
    warningIntervalSeconds: 600,
    dockSleepLevel: 95,
    isPlugged: false
  },
  navigation: {
    backOnAudioEnd: true,
    backOnVideoEnd: true,
    nextPageOnMediaEnd: true,
    resolveBeaconWhilePlaying: false,
    autoPlayPageAudio: true,
    playBeepOnResolvedBeacon: true,
    vibrateOnResolvedBeacon: true,
    numpadInputExtraButtons: [],
    showWelcomeScreen: false,
    autoHideNavDuration: 2000,
    showChildPagesMenu: true,
    mediaIgnoresContextMenu: false
  },
  theme: 't-clear',
  showDeviceName: false,
  exitAppNow: null,
  evaluationEnabled: null,
  allowHardwareVolumeControl: false,
  showLocationSettingsWarning: false,
  showSubtitlesDefault: false,
  zoomRatio: null,
  maxLipsyncLatency: { audio: 40, video: 1500 },
  isPanoramaDeviceOrientationEnabled: true
}
