import axios from 'axios'
import Vue from 'vue'
const vm = new Vue()

const getSettings = async function (langCode = null) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      if (langCode === null || langCode === '_') {
        window.XpedeoPreloader.getSettings(
          (settings) => {
            resolve(settings)
          },
          function (errMsg) {
            reject(new Error(errMsg))
          }
        )
      } else {
        window.XpedeoPreloader.getSettingsLang(langCode,
          (settings) => {
            resolve(settings)
          },
          function (errMsg) {
            reject(new Error(errMsg))
          }
        )
      }
    } else {
      const getRemappedUrl = async function (url) {
        return new Promise(function (resolve, reject) {
          window.XpedeoPreloader.getRemappedUrl(
            url,
            (remappedUrl) => {
              resolve(remappedUrl)
            },
            (errMsg) => {
              reject(new Error(errMsg))
            })
        })
      }
      const configPageID = window.XPEDEO_SETTINGS.content.configPageID || 37
      const remoteUrl = window.XPEDEO_SETTINGS.content.remoteUrl
      const useStaticPageFiles = typeof (window.XPEDEO_SETTINGS.content.useStaticPageFiles) !== 'undefined' && window.XPEDEO_SETTINGS.content.useStaticPageFiles
      let url = ''
      // case of lang link (see router guards), [null || _] means de ( default )
      if (langCode === null || langCode === '_' || langCode === ' ' || langCode === '') {
        url = remoteUrl + 'xpedeo/' + (useStaticPageFiles ? `P${configPageID}.json` : `api/v1/pages/${configPageID}`)
      } else {
        url = remoteUrl + 'xpedeo/' + (langCode.includes('/') ? langCode : langCode + '/') + (useStaticPageFiles ? `P${configPageID}.json` : `api/v1/pages/${configPageID}`)
      }

      console.log('vm.$xp.device.platform', vm.$xp.device.platform)
      if (vm.$xp.device.platform === 'android' && window.XpedeoPreloader) {
        const remappedUrl = getRemappedUrl(url)
          .then((remappedUrl) => {
            console.log('getSettings remappedUrl', remappedUrl)
            // } else {
            if (remappedUrl === url) {
              axios.get(remappedUrl)
                .then((response) => {
                  console.log(response.data.Settings)
                  resolve(response.data.Settings)
                // settingsObject = response.data.Settings
                })
                .catch(e => reject(e))
            } else {
              window.XpedeoPreloader.getSettings(
                remappedUrl,
                (settings) => {
                  console.log(settings)
                  const jsonParsed = JSON.parse(settings)
                  console.log('jsonParsed', jsonParsed.Settings)
                  resolve(jsonParsed.Settings)
                },
                (errMsg) => {
                  reject(new Error(errMsg))
                })
            }
          })
      } else {
        if (vm.$xp.device.platform === 'ios') {
          if (window.XpedeoPreloader) {
            url = window.XpedeoPreloader.convertProxyUrl(url)
          }
        }
        console.log('vm.$xp.device.platform', url)
        axios.get(url)
          .then(response => {
            resolve(response.data.Settings)
          })
          .catch(e => reject(e))
      }
    }
  })
}

const setSettings = function (jsonString) {
  return new Promise(function (resolve, reject) {
    if (window.device.platform.toLowerCase() === 'android') {
      window.XpedeoPreloader.setSettings(
        jsonString,
        () => {
          resolve(JSON.parse(jsonString))
        },
        (errMsg) => {
          reject(new Error(errMsg))
        }
      )
    } else {
      resolve(JSON.parse(jsonString))
    }
  })
}

export default {
  getSettings,
  setSettings
}
