import localPageDataMixin from './localPageData'
import debug from 'debug'

const log = debug('xpedeo-core:PageMixin')
debug.enable(process.env.VUE_APP_DEBUG)

const page = {
  mixins: [localPageDataMixin],
  computed: {
    pageData () {
      return this.localPageData || this.$xp.content.pageData
    }
  },
  created () {
    // console.log(this.$xp.history.__historyArray)
    log('pageMixin created', this.pageData, this.pageData.Homepage)
    // eslint-disable-next-line no-prototype-builtins
    if (!this.hasOwnProperty('isFirstFocus')) this.isFirstFocus = true
    this.$xp.history.nextPageId = this.$xp.content.pageData.NextPageID
    /**
     * this condition is important to avoid tabs flickering
     * - on router change pageData will be set to {} till loading the new page or error happen
     * - ignore XpHome tabs, only children tabs are important
     */
    // if (this.pageData !== {} && this.pageData.Class !== 'XpHome') {
    if (this.pageData.Homepage) {
      if (this.pageData.NfcPageId) {
        log('in pageMixin, Homepage, set NfcPageID=', this.pageData.NfcPageId)
        window.localStorage.setItem('nfcPageId', this.pageData.NfcPageId)
      }
      // console.log('in pagejs addHomePage', `/page/${this.$route.params.id}`, this.pageData.Title, this.pageData.Homepage.ParentID, this.pageData)
      // this.$xp.history.addHomePage(this.pageData.Homepage.ID, `/page/${this.$route.params.id}`, this.pageData.Title, this.pageData.Homepage.ParentID)
      this.$xp.navigation.setTabs(this.pageData.Homepage.Tabs)
      this.$xp.navigation.setActiveTabId(this.pageData.ID)
    } else {
      this.$xp.navigation.resetTabs()
    }
    // }
    // Set language and thereby rtl
    if (this.pageData.Language && this.pageData.Language.Code && this.pageData.Language.Code !== this.$xp.polyglot.locale) {
      this.$xp.polyglot.setLanguage(this.pageData.Language.Code, this.pageData.Language.Path)
    }
    if (this.pageData.children && this.pageData.children.length && this.pageData.Class !== 'XpMenu') {
      this.$xp.navigation.setChildPagesMenuItems(this.pageData.children.map(item => {
        return {
          id: item.ID,
          title: item.Title,
          class: item.Class,
          icon: item.Icon
        }
      }))
    } else {
      this.$xp.navigation.resetChildPagesMenuItems()
    }
  },
  methods: {
    async setScrollFromHistory (key = undefined) {
      // get last scroll value from history array and set it
      if (!key) {
        key = 'lastScrollTop'
      }
      const scrollableElement = document.querySelector('div.js-page-content')
      let lastScrollTop = NaN
      if (this.$xp.history.historyParams && this.$xp.history.historyParams[key]) {
        lastScrollTop = this.$xp.history.historyParams[key]
      }
      if (scrollableElement && !isNaN(lastScrollTop)) {
        log('set scroll from history: ' + lastScrollTop)
        scrollableElement.scrollTop = lastScrollTop
        // set again for delayed render
        setTimeout(() => { scrollableElement.scrollTop = lastScrollTop }, 0)
      } else {
        log('set scroll to 0')
        scrollableElement.scrollTop = 0
      }
    },
    setFocusFromHistory () {
      /**
       * todo: check if screen reader is active and set focus accordingly ( for screenreader only on pagetitle )
       * ask ralph about the current implementation
       */
      if (!this.firstFocus) {
        setTimeout(() => {
          let element = null
          // eslint-disable-next-line dot-notation
          let selector = null
          // eslint-disable-next-line dot-notation
          if (this.$xp.history.historyParams && this.$xp.history.historyParams['focus']) {
            // eslint-disable-next-line dot-notation
            selector = this.$xp.history.historyParams['focus']
          }
          if (selector) element = document.querySelector(selector)
          if (!element) element = document.querySelector('#pageTitle')
          console.log('setFocusFromHistory() "', selector, '", element:', element)
          if (element) {
            element.focus()
          } else {
            const pageTitle = document.querySelector('#pageTitle')
            pageTitle.focus()
          }
        }, 100)
      } else {
        this.firstFocus = false
      }
    },
    setChildPagesHiddenFromHistory () {
      // get last child pages value from history array and set it
      if (this.$xp.navigation.childPagesMenuItems.length) {
        const lastChildPagesState = this.$xp.history.historyParams.lastChildPagesState
        if (lastChildPagesState !== undefined && lastChildPagesState !== this.$xp.navigation.isChildPagesMenuHidden) {
          this.$xp.navigation.toggleChildPagesMenu()
        }
      }
    }
  },
  mounted () {
    console.log('pageMixin mounted', this.pageData, this.pageData.Homepage)
    this.$xp.clientsettings.applyClientSettings()
    this.setScrollFromHistory()
    this.setChildPagesHiddenFromHistory()
    this.setFocusFromHistory()
  },
  watch: {
    '$xp.navigation.currentEntry': {
      handler (newVal) {
        if (!this.$xp.navigation.isFullScreen && this.$xp.navigation.isAudioSynced) {
          this.$scrollTo('#' + newVal)
        }
      }
    }
  }
}

export default page
